<template>
  <div
    class="toast"
    id="myToast"
    role="alert"
    aria-live="polite"
    aria-atomic="true"
    data-bs-delay="10000"
  >
    <div role="alert" aria-live="assertive" aria-atomic="true">...</div>
  </div>
  <div class="container-fluid h-100 d-flex flex-column scrollable-table router-view-scrollable-content">
    <div class="text-center pb-3">
      <h2>{{ $t("PROJECTS") }}</h2>
      <p class="px-5 pt-3">{{ $t("PROJECTS_SUBTITLE") }}</p>
    </div>

    <div class="mb-4 row">
      <div class="col-3" v-if="markets.length > 1">
        <p class="mb-1">{{ $t("SELECT_A_MARKET") }}</p>
        <select
          class="form-select market-select"         
          @input="setUserMarket"
        >
          <option
            v-for="market in markets"
            :key="market.guid"
            :value="market.guid"
            :selected="user_market?.guid === market.guid"
          >
            <span v-if="market.short"
              >{{ market.short }} ({{ market.name }})</span
            >
            <span v-else>{{ market.name }}</span>
          </option>
        </select>
      </div>
      <div class="col-3" v-if="activeLanguages && activeLanguages.length > 1">
        <p class="mb-1">{{ $t("SELECT_LANGUAGE") }}</p>
        <select
          class="form-select"
          v-model="user.languageCode"
          @input="setUserLanguage"
        >
          <option
            v-for="language in activeLanguages"
            :key="language.id"
            :value="language.languageCode"
          >
            <span>{{ language.languageCode }} ({{ language.name }})</span>
          </option>
        </select>
      </div>
      <div class="col-1"></div>
      <div
        v-if="NoMarketUser"
        class="nomarketAlert bg-primary border border-secondary text-center p-1 col text-white"
      >
        <h3>{{ $t("NO_MARKET_ASSIGNED") }}</h3>
      </div>
    </div>

    <div class="table-responsive flex-full-height w-100 mb-3">
      <table class="table">
        <thead class="table-light">
          <tr>
            <th scope="col">Project Name</th>
            <th class="col"></th>
          </tr>
        </thead>
        <tbody>
          <h5
            class="text-muted text-center"
            v-if="!projects || projects.length == 0"
          >
            {{ $t("NO_PROJECTS_ACCESSIBLE") }}
          </h5>
          <tr v-else v-for="project of projects" :key="project.guid">
            <td class="align-middle">
              <p class="mb-0">{{ project.name }}</p>
            </td>
            <td>
              <button
                v-show="!showSpinner"
                type="button"
                class="btn btn-primary w-100"
                data-jp="start-project"
                :disabled="NoMarketUser"
                @click="selectProject(project)"
              >
                {{ $t("START") }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters, useStore } from "vuex";

export default {
  name: "Projects",
  computed: {
    ...mapGetters(["showSpinner"]),
    NoMarketUser() {
      return !this.user_market;
    },
    projects() {
      return this.$store.getters["projects/getProjects"];
    },
    markets() {
      return this.$store.getters["markets/getMarkets"];
    },
    waves() {
      return this.$store.getters["waves/getWaves"];
    },
    user_market() {
      return this.$store.getters["users/getUserMarket"];
    },
    activeLanguages() {
      return this.$store.getters["translations/getActiveLanguages"];
    },
    user() {
      return this.$store.getters["users/getUser"];
    },
  },
  async setup() {
    const store = useStore();
    await store.dispatch("markets/getMarkets");
    await store.dispatch("projects/loadProjects");
    return {
      icon_running: require("../assets/icons/running.svg"),
      icon_configure: require("../assets/icons/configure.svg"),
      icon_closed_done: require("../assets/icons/closed_done.svg"),
      selected_market_guid: store.getters["users/getUserMarket"].guid,
    };
  },
  watch: {
    markets() {
      this.selected_market_guid = this.user_market?.guid;
      if (this.markets.length > 0 && this.selected_market_guid == null) {
        this.selected_market_guid = this.markets[0].guid;
      }
    },
  },
  methods: {
    async setUserLanguage(event) {
      await this.$store.dispatch("users/selectLanguage", event.target.value);
    },
    async setUserMarket(event) {
      let market = this.markets.find((m) => m.guid === event.target.value);
      await this.$store.dispatch("users/setUserMarket", market);
    },
    async selectProject(project) {
      await this.$router.push({ path: `/projects/${project.guid}/waves` });
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}
.market-select {
  width: max-content;
}
</style>
